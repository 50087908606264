import React from 'react';
import { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { UseAppSelector } from '../../../../store/hooks';
import Fade from 'react-reveal/Fade';
import FormatCurrency from '../../../../scripts/formatCurrency';

import '../../../../styles/desktop/calculators/sub_components/performanceOutput.css';

function PerformanceOutput() {
    // UI states
    const [colorState, setColorState] = useState('rgb(84, 84, 84)');

    //redux
    const data = UseAppSelector((state) => state.stats);
    const BE = data.find(item => item.title === 'business-efficiency');
    const MPU = data.find(item => item.title === 'minutes-per-unit');
    const DPH = data.find(item => item.title === 'dollars-per-hour');
    const DPU = data.find(item => item.title === 'dollars-per-unit');
    
    // change color of plot based on effciency value
    const efficiency = BE.value;
    useEffect(() => {
        if(efficiency >= 80) {
            setColorState('rgb(20, 229, 86)');
        } else if(efficiency >= 60) {
            setColorState('rgb(239, 232, 28)');
        } else {
            setColorState('rgb(239, 28, 70)');
        }
    }, [efficiency])

    return (
        <section className="performance-section">
            <Fade right cascade>
                <div className="performance-parent">
                    <div className="performance-card">
                        <label className="performance-title">Business Efficiency</label>
                        <h2 className="h2a">{efficiency}%</h2>
                        <div className="doughnut">
                            <Doughnut
                                data={{
                                    datasets: [{
                                        label: 'GTA Dollars',
                                        data: [efficiency, (100-efficiency)],
                                        backgroundColor: [colorState, 'rgba(204, 204, 204, 0.8)'],
                                        borderRadius: 3
                                    }]
                                }}
                                height={130}
                                width={157}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: false,
                                    radius: 50,
                                    borderWidth: 0,
                                    cutout: '80%'
                                    
                                }}
                            />
                        </div>
                    </div>
                    <div className="performance-card">
                        <label className="performance-title">Minutes Per Unit</label>
                        <h2 className="h2b">{MPU.value}</h2>
                    </div>
                    <div className="performance-card">
                        <label className="performance-title">Dollars Per Hour</label>
                        <h2 className="h2c">GTA {FormatCurrency(DPH.value)}</h2>
                    </div>
                    <div className="performance-card">
                        <label className="performance-title">Dollars Per Unit</label>
                        <h2 className="h2c">GTA {FormatCurrency(DPU.value)}</h2>
                    </div>
                </div>
            </Fade>
        </section>
    )
}

export default PerformanceOutput;
