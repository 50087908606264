import React from 'react';
import { Modal } from 'react-bootstrap';

import * as styles from '../../../styles/flex/calculators/optimalBusinessModal.module.css';

function OptimalBusinessModal(props) {
    return (
      <Modal {...props} className={styles.ModalContainer} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        
        <Modal.Header closeButton>
            <Modal.Title className={styles.ModalTitle} id="contained-modal-title-vcenter">
                <h2>Optimal Business</h2>
            </Modal.Title>
        </Modal.Header>

        <Modal.Body className={styles.ModalBody}>
            <h4>To earn the maximum dollars per hour, select these options:</h4>
            <ul>
                <li>Performance Upgrade</li>
                <li>Staff Upgrade</li>
                <li>Sell Far</li>
                <li>Steal Supplies</li>
            </ul>
        </Modal.Body>

      </Modal>
    );
}

export default OptimalBusinessModal;