import { createSlice } from '@reduxjs/toolkit';

import img1 from '../images/lockup_images/maps/all_lockup_locations.png';
import img2 from '../images/lockup_images/maps/morningwood_lockup.png';
import img3 from '../images/lockup_images/maps/elysian_island_lockup.png';
import img4 from '../images/lockup_images/maps/paleto_bay_lockup.png';
import img5 from '../images/lockup_images/maps/alamo_sea_lockup.png';
import equipment_img from '../images/lockup_images/lockup_equipment_upgrade.png';
import staff_img from '../images/lockup_images/lockup_staff_upgrade.png';
import security_img from '../images/lockup_images/lockup_security_upgrade.png';
import banner_img from '../images/lockup_images/cocaine-lockup-image.jpg'

const lockupSlice = createSlice({
    name: 'lockup',
    initialState: [
        // Location Filter Options
        { id: 0, filter_title1: 'Free', filter_title2: '-1 Million', filter_title3: '+1 Million' },
        // Location options data
        { id: 1, image: img1, title: 'Twitch Prime Lockup', category: 'free', cost: 0 },
        { id: 2, image: img2, title: 'Morningwood Lockup', category: 'over', cost: 1852500 },
        { id: 3, image: img3, title: 'Elysian Island Lockup', category: 'over', cost: 1462500 },
        { id: 4, image: img4, title: 'Paleto Bay Lockup', category: 'over', cost: 1098000 },
        { id: 5, image: img5, title: 'Alamo Sea Lockup', category: 'under', cost: 975000 },
        // Business specific data
        { id: 6, title: 'current-reducer', reducer: 'lockup', banner: banner_img },
        {
            id: 7,
            title: 'no-upgrade',
            cost: 0,
            utils: 4500,
            DPU: 20000,
            MPU: 50,
            cap: 10,
            STUE: 1.5,
            SV: 170000,
            sale_max: 500000
        },
        {
            id: 8,
            title: 'equip-upgrade',
            display_title: 'Equipment Upgrade',
            image: equipment_img,
            cost: 935000,
            utils_inc: 1500,
            DPU_inc: 4000,
            MPU_inc: 10,
            cap: 10,
            STUE_inc: 0.5,
            BE_inc: 20
        },
        {
            id: 9,
            title: 'staff-upgrade',
            display_title: 'Staff Upgrade',
            image: staff_img,
            cost: 390000,
            utils_inc: 2250,
            DPU_inc: 4000,
            MPU_inc: 10,
            cap: 10,
            STUE_inc: 0.5,
            BE_inc: 30
        },
        {
            id: 10,
            title: 'security-upgrade',
            display_title: 'Security Upgrade',
            image: security_img,
            cost: 570000,
            utils_inc: 750,
            DPU_inc: 0,
            MPU_inc: 0,
            cap: 10,
            STUE_inc: 0,
            BE_inc: 0
        },
        {
            id: 11,
            title: 'sell-close-far',
            cap: 10,
            BE_inc: 20,
        },
        {
            id: 12,
            title: 'buy-steal-supplies',
            cap: 10,
            BE_inc: 20,
        },
        {
            id: 13,
            title: 'sale-values', 
            ffff: 170000,
            tttt: 420000,
            tfff: 204000,
            ftff: 204000,
            fftf: 255000,
            ffft: 200000,
            ttff: 238000,
            tftf: 306000,
            tfft: 240000,
            fttf: 306000,
            ftft: 240000,
            fftt: 300000,
            tttf: 357000,
            ttft: 280000,
            tftt: 360000,
            fttt: 360000
        },
        {
            id: 14,
            title: 'DPU-values', 
            ttt: 42000,
            ttf: 28000,
            tft: 36000,
            tff: 24000,
            ftt: 36000,
            ftf: 24000,
            fft: 30000,
            fff: 20000
        },
    ],
    reducers: {
        
    }
});

export const {  } = lockupSlice.actions;

export default lockupSlice.reducer;