// Currency formatter USD
function FormatCurrency(num) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency', 
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    });

    const new_num = formatter.format(num);

    return new_num;
}

export default FormatCurrency;