import { createSlice } from '@reduxjs/toolkit';

import img1 from '../images/lab_images/maps/all_meth_lab_locations.png';
import img2 from '../images/lab_images/maps/el_burro_heights_meth_lab.png';
import img3 from '../images/lab_images/maps/terminal_meth_lab.png';
import img4 from '../images/lab_images/maps/paleto_bay_meth_lab.png';
import img5 from '../images/lab_images/maps/grand_senora_desert_meth_lab.png';
import equipment_img from '../images/lab_images/lab_equipment_upgrade.png';
import staff_img from '../images/lab_images/lab_staff_upgrade.png';
import security_img from '../images/lab_images/lab_security_upgrade.png';
import banner_img from '../images/lab_images/lab_banner.jpg';

const labSlice = createSlice({
    name: 'lab',
    initialState: [
        // Location Filter Options
        { id: 0, filter_title1: 'Free', filter_title2: '-1 Million', filter_title3: '+1 Million' },
        // Location options data
        { id: 1, image: img1, title: 'Twitch Prime', category: 'free', cost: 0 },
        { id: 2, image: img2, title: 'El Burro Heights', category: 'over', cost: 1729000 },
        { id: 3, image: img3, title: 'Terminal', category: 'over', cost: 1365000 },
        { id: 4, image: img4, title: 'Paleto Bay', category: 'over', cost: 1024800 },
        { id: 5, image: img5, title: 'Grand Senora Desert', category: 'under', cost: 910000 },
        // Business specific data
        { id: 6, title: 'current-reducer', reducer: 'meth lab', banner: banner_img },
        {
            id: 7,
            title: 'no-upgrade',
            cost: 0,
            utils: 3600,
            DPU: 8500,
            MPU: 30,
            cap: 20,
            STUE: 1.5,
            SV: 144500,
            sale_max: 400000
        },
        {
            id: 8,
            title: 'equip-upgrade',
            display_title: 'Equipment Upgrade',
            image: equipment_img,
            cost: 1100000,
            utils_inc: 1200,
            DPU_inc: 1700,
            MPU_inc: 6,
            cap: 20,
            STUE_inc: 0.5,
            BE_inc: 20
        },
        {
            id: 9,
            title: 'staff-upgrade',
            display_title: 'Staff Upgrade',
            image: staff_img,
            cost: 331500,
            utils_inc: 1800,
            DPU_inc: 1700,
            MPU_inc: 6,
            cap: 20,
            STUE_inc: 0.5,
            BE_inc: 30
        },
        {
            id: 10,
            title: 'security-upgrade',
            display_title: 'Security Upgrade',
            image: security_img,
            cost: 513000,
            utils_inc: 600,
            DPU_inc: 0,
            MPU_inc: 0,
            cap: 20,
            STUE_inc: 0,
            BE_inc: 0
        },
        {
            id: 11,
            title: 'sell-close-far',
            cap: 20,
            BE_inc: 20,
        },
        {
            id: 12,
            title: 'buy-steal-supplies',
            cap: 20,
            BE_inc: 20,
        },
        {
            id: 13,
            title: 'sale-values', 
            ffff: 144500,
            tttt: 357000,
            tfff: 173400,
            ftff: 173400,
            fftf: 216750,
            ffft: 170000,
            ttff: 202300,
            tftf: 260100,
            tfft: 204000,
            fttf: 260100,
            ftft: 204000,
            fftt: 255000,
            tttf: 303450,
            ttft: 238000,
            tftt: 306000,
            fttt: 306000
        },
        {
            id: 14,
            title: 'DPU-values', 
            ttt: 17850,
            ttf: 11900,
            tft: 15300,
            tff: 10200,
            ftt: 15300,
            ftf: 10200,
            fft: 12750,
            fff: 8500
        },
    ],
    reducers: {
        
    }
});

export const {  } = labSlice.actions;

export default labSlice.reducer;