import { createSlice } from '@reduxjs/toolkit';

import img1 from '../images/bunker_images/maps/all_bunker_locations.png';
import img2 from '../images/bunker_images/maps/farmhouse_bunker.png';
import img3 from '../images/bunker_images/maps/thomson_bunker.png';
import img4 from '../images/bunker_images/maps/smoke_bunker.png';
import img5 from '../images/bunker_images/maps/desert_bunker.png';
import img6 from '../images/bunker_images/maps/oilfields_bunker.png';
import img7 from '../images/bunker_images/maps/route_68_bunker.png';
import img8 from '../images/bunker_images/maps/grapeseed_bunker.png';
import img9 from '../images/bunker_images/maps/chumash_bunker.png';
import img10 from '../images/bunker_images/maps/zancudo_bunker.png';
import img11 from '../images/bunker_images/maps/canyon_bunker.png';
import img12 from '../images/bunker_images/maps/paleto_forest_bunker.png';
import equipment_img from '../images/bunker_images/bunker_equipment_upgrade.png';
import staff_img from '../images/bunker_images/bunker_staff_upgrade.png';
import security_img from '../images/bunker_images/bunker_security_upgrade.png';
import banner_img from '../images/bunker_images/gtao_bunker.jpg';

const bunkerSlice = createSlice({
    name: 'bunker',
    initialState: [
        // Location Filter Options
        { id: 0, filter_title1: 'Free', filter_title2: '-2 Million', filter_title3: '+2 Million' },
        // Location options data
        { id: 1, image: img1, title: 'Twitch Prime Bunker', category: 'free', cost: 0 },
        { id: 2, image: img2, title: 'Farmhouse Bunker', category: 'over', cost: 2375000 },
        { id: 3, image: img3, title: 'Thomson Bunker', category: 'over', cost: 2290000 },
        { id: 4, image: img4, title: 'Smoke Bunker', category: 'over', cost: 2203000 },
        { id: 5, image: img5, title: 'Desert Bunker', category: 'over', cost: 2120000 },
        { id: 6, image: img6, title: 'Oilfields Bunker', category: 'over', cost: 2035000 },
        { id: 7, image: img7, title: 'Route 68 Bunker', category: 'under', cost: 1950000 },
        { id: 8, image: img8, title: 'Grapeseed Bunker', category: 'under', cost: 1750000 },
        { id: 9, image: img9, title: 'Chumash Bunker', category: 'under', cost: 1650000 },
        { id: 10, image: img10, title: 'Zancudo Bunker', category: 'under', cost: 1550000 },
        { id: 11, image: img11, title: 'Canyon Bunker', category: 'under', cost: 1450000 },
        { id: 12, image: img12, title: 'Paleto Forest Bunker', category: 'under', cost: 1165000 },
        // Business specific data
        { id: 6, title: 'current-reducer', reducer: 'bunker', banner: banner_img },
        {
            id: 7,
            title: 'no-upgrade',
            cost: 0,
            utils: 4700,
            DPU: 5000,
            MPU: 10,
            cap: 100,
            STUE: 1.5,
            SV: 425000,
            sale_max: 1100000
        },
        {
            id: 8,
            title: 'equip-upgrade',
            display_title: 'Equipment Upgrade',
            image: equipment_img,
            cost: 1155000,
            utils_inc: 1600,
            DPU_inc: 1000,
            MPU_inc: 1.5,
            cap: 100,
            STUE_inc: 0.5,
            BE_inc: 20
        },
        {
            id: 9,
            title: 'staff-upgrade',
            display_title: 'Staff Upgrade',
            image: staff_img,
            cost: 598500,
            utils_inc: 2325,
            DPU_inc: 1000,
            MPU_inc: 1.5,
            cap: 100,
            STUE_inc: 0.5,
            BE_inc: 30
        },
        {
            id: 10,
            title: 'security-upgrade',
            display_title: 'Security Upgrade',
            image: security_img,
            cost: 351000,
            utils_inc: 775,
            DPU_inc: 0,
            MPU_inc: 0,
            cap: 100,
            STUE_inc: 0,
            BE_inc: 0
        },
        {
            id: 11,
            title: 'sell-close-far',
            cap: 100,
            BE_inc: 20,
        },
        {
            id: 12,
            title: 'buy-steal-supplies',
            cap: 100,
            BE_inc: 20,
        },
        {
            id: 13,
            title: 'sale-values', 
            ffff: 425000,
            tttt: 1050000,
            tfff: 510000,
            ftff: 510000,
            fftf: 637500,
            ffft: 500000,
            ttff: 595000,
            tftf: 765000,
            tfft: 600000,
            fttf: 765000,
            ftft: 600000,
            fftt: 750000,
            tttf: 892500,
            ttft: 700000,
            tftt: 900000,
            fttt: 900000
        },
        {
            id: 14,
            title: 'DPU-values', 
            ttt: 10500,
            ttf: 7000,
            tft: 9000,
            tff: 6000,
            ftt: 9000,
            ftf: 6000,
            fft: 7500,
            fff: 5000
        },
    ],
    reducers: {
        
    }
});

export const {  } = bunkerSlice.actions;

export default bunkerSlice.reducer;