import React from 'react';
import Slide from 'react-reveal/Slide';

import '../../../../styles/mobile/calculators/sub_components/mobileCostsInfo.css';

function MobileCostsInfo() {
    return (
        <Slide top>
            <div className="costs-info">
                <div className="arrow"></div>
                <h2>Business Costs</h2>
                <hr/>
            </div>
        </Slide>
    )
}

export default MobileCostsInfo;