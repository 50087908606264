import React from 'react';
import { Bar } from 'react-chartjs-2';
import { UseAppSelector } from '../../../../store/hooks';
import Fade from 'react-reveal/Fade';

import '../../../../styles/desktop/calculators/sub_components/salesSupplyOutput.css';

function SalesSupplyOutput() {
    // redux
    const statsEngine = UseAppSelector((state) => state.stats);
    const dataObject = statsEngine.find(item => item.title === 'business-data');
    const data = dataObject.data;

    const SV = statsEngine.find(item => item.title === 'sale-value');
    const STUE = statsEngine.find(item => item.title === 'supply-time-until-empty');
    const PTUF = statsEngine.find(item => item.title === 'product-time-until-full');
    const max = data.find(item => item.title === 'no-upgrade');

    return (
        <section className="salesupp-section">
            <Fade right cascade>
                <div className="salesupp-parent">
                    <div className="div1a">
                        <label className="salesupp-title">Sale Profits</label>
                        <div className="bar">
                            <Bar
                                data={{
                                    labels: ['Sale Profits'],
                                    datasets: [{
                                        label: 'GTA $',
                                        data: [SV.value],
                                        backgroundColor: ['rgba(255, 114, 192, 0.8)'],
                                        borderRadius: 10,
                                        borderSkipped: false
                                    }]
                                }}
                                height={320}
                                width={190}
                                options={{
                                    maintainAspectRatio: false,
                                    responsive: false,
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        datalabels: {
                                            color: '#fff',
                                            display: true
                                        }
                                    },
                                    scales: {
                                        y: {
                                            ticks: {
                                                display: true,
                                                stepSize: 100000
                                            },
                                            max: max.sale_max

                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="div2a">
                        <label className="salesupp-title">Supply Time Until Empty</label>
                        <div><h2>{STUE.value}</h2><p>Hours</p></div>
                    </div>
                    <div className="div3a">
                        <label className="salesupp-title">Product Time Until Full</label>
                        <div><h2>{PTUF.value}</h2><p>Hours</p></div>
                    </div>
                </div>
            </Fade>
        </section>
    )
}

export default SalesSupplyOutput;