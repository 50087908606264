// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-feedback-contact-form-js": () => import("./../../../src/pages/feedback/contact-form.js" /* webpackChunkName: "component---src-pages-feedback-contact-form-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-more-profit-tables-js": () => import("./../../../src/pages/more/profit-tables.js" /* webpackChunkName: "component---src-pages-more-profit-tables-js" */),
  "component---src-pages-more-road-map-js": () => import("./../../../src/pages/more/road-map.js" /* webpackChunkName: "component---src-pages-more-road-map-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profit-calculators-gta-online-biker-businesses-js": () => import("./../../../src/pages/profit-calculators/gta-online-biker-businesses.js" /* webpackChunkName: "component---src-pages-profit-calculators-gta-online-biker-businesses-js" */),
  "component---src-pages-profit-calculators-gta-online-bunker-profit-calculator-js": () => import("./../../../src/pages/profit-calculators/gta-online-bunker-profit-calculator.js" /* webpackChunkName: "component---src-pages-profit-calculators-gta-online-bunker-profit-calculator-js" */),
  "component---src-pages-profit-calculators-gta-online-cocaine-lockup-profit-calculator-js": () => import("./../../../src/pages/profit-calculators/gta-online-cocaine-lockup-profit-calculator.js" /* webpackChunkName: "component---src-pages-profit-calculators-gta-online-cocaine-lockup-profit-calculator-js" */),
  "component---src-pages-profit-calculators-gta-online-counterfeit-cash-profit-calculator-js": () => import("./../../../src/pages/profit-calculators/gta-online-counterfeit-cash-profit-calculator.js" /* webpackChunkName: "component---src-pages-profit-calculators-gta-online-counterfeit-cash-profit-calculator-js" */),
  "component---src-pages-profit-calculators-gta-online-document-forgery-profit-calculator-js": () => import("./../../../src/pages/profit-calculators/gta-online-document-forgery-profit-calculator.js" /* webpackChunkName: "component---src-pages-profit-calculators-gta-online-document-forgery-profit-calculator-js" */),
  "component---src-pages-profit-calculators-gta-online-meth-lab-profit-calculator-js": () => import("./../../../src/pages/profit-calculators/gta-online-meth-lab-profit-calculator.js" /* webpackChunkName: "component---src-pages-profit-calculators-gta-online-meth-lab-profit-calculator-js" */),
  "component---src-pages-profit-calculators-gta-online-weed-farm-profit-calculator-js": () => import("./../../../src/pages/profit-calculators/gta-online-weed-farm-profit-calculator.js" /* webpackChunkName: "component---src-pages-profit-calculators-gta-online-weed-farm-profit-calculator-js" */)
}

