import { createSlice } from '@reduxjs/toolkit';

import img1 from '../images/farm_images/maps/all_weed_farm_locations.png';
import img2 from '../images/farm_images/maps/downtown_vinewood_weed_farm.png';
import img3 from '../images/farm_images/maps/elysian_island_weed_farm.png';
import img4 from '../images/farm_images/maps/mount_chiliad_weed_farm.png';
import img5 from '../images/farm_images/maps/san_chianski_mountain_range_weed_farm.png';
import equipment_img from '../images/farm_images/farm_equipment_upgrade.png';
import staff_img from '../images/farm_images/farm_staff_upgrade.png';
import security_img from '../images/farm_images/farm_security_upgrade.png';
import banner_img from '../images/farm_images/farm_banner.jpg';

const farmSlice = createSlice({
    name: 'farm',
    initialState: [
        // Location Filter Options
        { id: 0, filter_title1: 'Free', filter_title2: '-1 Million', filter_title3: '+1 Million' },
        // Location options data
        { id: 1, image: img1, title: 'Twitch Prime', category: 'free', cost: 0 },
        { id: 2, image: img2, title: 'Downtown Vinewood', category: 'over', cost: 1358500 },
        { id: 3, image: img3, title: 'Elysian Island', category: 'over', cost: 1072500 },
        { id: 4, image: img4, title: 'Mount Chiliad', category: 'under', cost: 805200 },
        { id: 5, image: img5, title: 'San Chianksi Mountain', category: 'under', cost: 715000 },
        // Business specific data
        { id: 6, title: 'current-reducer', reducer: 'weed farm', banner: banner_img },
        {
            id: 7,
            title: 'no-upgrade',
            cost: 0,
            utils: 2400,
            DPU: 1500,
            MPU: 6,
            cap: 80,
            STUE: 1.5,
            SV: 102000,
            sale_max: 300000
        },
        {
            id: 8,
            title: 'equip-upgrade',
            display_title: 'Equipment Upgrade',
            image: equipment_img,
            cost: 990000,
            utils_inc: 800,
            DPU_inc: 200,
            MPU_inc: 1,
            cap: 80,
            STUE_inc: 0.5,
            BE_inc: 20
        },
        {
            id: 9,
            title: 'staff-upgrade',
            display_title: 'Staff Upgrade',
            image: staff_img,
            cost: 273000,
            utils_inc: 1200,
            DPU_inc: 200,
            MPU_inc: 1,
            cap: 80,
            STUE_inc: 0.5,
            BE_inc: 30
        },
        {
            id: 10,
            title: 'security-upgrade',
            display_title: 'Security Upgrade',
            image: security_img,
            cost: 313500,
            utils_inc: 400,
            DPU_inc: 0,
            MPU_inc: 0,
            cap: 20,
            STUE_inc: 0,
            BE_inc: 0
        },
        {
            id: 11,
            title: 'sell-close-far',
            cap: 80,
            BE_inc: 20,
        },
        {
            id: 12,
            title: 'buy-steal-supplies',
            cap: 80,
            BE_inc: 20,
        },
        {
            id: 13,
            title: 'sale-values', 
            ffff: 102000,
            tttt: 252000,
            tfff: 122400,
            ftff: 122400,
            fftf: 153000,
            ffft: 120000,
            ttff: 142800,
            tftf: 183600,
            tfft: 144000,
            fttf: 183600,
            ftft: 144000,
            fftt: 180000,
            tttf: 214200,
            ttft: 168000,
            tftt: 216000,
            fttt: 216000
        },
        {
            id: 14,
            title: 'DPU-values', 
            ttt: 3150,
            ttf: 2100,
            tft: 2700,
            tff: 1800,
            ftt: 2700,
            ftf: 1800,
            fft: 2250,
            fff: 1500
        },
    ],
    reducers: {
        
    }
});

export const {  } = farmSlice.actions;

export default farmSlice.reducer;