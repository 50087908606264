import { configureStore } from "@reduxjs/toolkit";

import statsReducer from './statsEngine';
import bunkerReducer from './bunkerSlice';
import lockupReducer from './lockupSlice';
import forgeryReducer from './forgerySlice';
import counterfeitReducer from './counterfeitSlice';
import labSlice from "./labSlice";
import farmSlice from "./farmSlice";

const createStore = () => configureStore({
    reducer: {
        stats: statsReducer,
        bunker: bunkerReducer,
        lockup: lockupReducer,
        forgery: forgeryReducer,
        counterfeit: counterfeitReducer,
        lab: labSlice,
        farm: farmSlice
    }
});

type ConfiguredStore = ReturnType<typeof createStore>;
type StoreGetState = ConfiguredStore["getState"];
export type RootState = ReturnType<StoreGetState>;
export type AppDispatch = ConfiguredStore["dispatch"];

export default createStore;