import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

import PerformanceOutput from '../components/desktop/calculators/sub_components/PerformanceOutput';
import CostsOutput from '../components/desktop/calculators/sub_components/CostsOutput';
import SalesSupplyOutput from '../components/desktop/calculators/sub_components/SalesSupplyOutput';
import MobilePerformanceInfo from '../components/mobile/calculators/sub_components/mobilePerformanceInfo';
import MobileCostsInfo from '../components/mobile/calculators/sub_components/mobileCostsInfo';
import MobileSalesSupplyInfo from '../components/mobile/calculators/sub_components/mobileSalesSupplyInfo';
import MobileBusinessModifiers from '../components/mobile/calculators/sub_components/mobileBusinessModifiers';

const statsEngine = createSlice({
    name: 'stats',
    initialState: [
        // Business stats dashboard components
        { id: 0, category: 'performance', component: <PerformanceOutput/>, sub_component: <MobilePerformanceInfo/> },
        { id: 1, category: 'costs', component: <CostsOutput/>, sub_component: <MobileCostsInfo/> },
        { id: 2, category: 'sales-supply', component: <SalesSupplyOutput/>, sub_component: <MobileSalesSupplyInfo/> },
        // User selection data
        { id: 3, title: 'Location', value: 0 },
        { id: 4, title: 'Equipment Upgrade', value: 0, checked: false },
        { id: 5, title: 'Security Upgrade', value: 0, checked: false },
        { id: 6, title: 'Staff Upgrade', value: 0, checked: false },
        // Business stats dashboard component data
        { id: 7, title: 'business-efficiency', value: 10 },
        { id: 8, title: 'minutes-per-unit', value: 0 },
        { id: 9, title: 'dollars-per-hour', value: 0 },
        { id: 10, title: 'dollars-per-unit', default_value: 0, value: 0},
        { id: 11, title: 'total-cost', value: 0 },
        { id: 12, title: 'upgrades-cost', value: 0 },
        { id: 13, title: 'payback-period', value: 0 },
        { id: 14, title: 'sale-value', value: 0 },
        { id: 15, title: 'supply-time-until-empty', value: 0 },
        { id: 16, title: 'product-time-until-full', value: 0 },
        // Additonal stats modifiers
        { id: 17, title: 'Optimize Business', value: 0, checked: false },
        { id: 18, title: 'Sell Close/Far', value: 0, prev_checked: false, checked: false },
        { id: 19, title: 'Steal/Buy Supplies', value: 0, checked: false },
        // Mobile UI additions
        { id: 20, category: 'business-modifiers', sub_component: <MobileBusinessModifiers/> },
        // Store data for the calculator being used
        { id: 21, title: 'business-data', reducer: null, data: null }
    ],
    reducers: {
        // Reset all statsEgine values to default
        resetStats: (state) => {
            state[3].value = 0;
            state[4].value = 0;
            state[4].checked = false;
            state[5].value = 0;
            state[5].checked = false;
            state[6].value = 0;
            state[6].checked = false;
            state[7].value = 10;
            state[8].value = 0;
            state[9].value = 0;
            state[10].default_value = 0;
            state[10].value = 0;
            state[11].value = 0;
            state[12].value = 0;
            state[13].value = 0;
            state[14].value = 0;
            state[15].value = 0;
            state[16].value = 0;
            state[17].value = 0;
            state[17].checked = false;
            state[18].value = 0;
            state[18].checked = false;
            state[18].prev_checked = false;
            state[19].value = 0;
            state[19].checked = false;
            state[21].reducer = null;
            state[21].data = null;
        },
        // Update statsEngine with base values for the given business
        initDashboard: (state, action) => {
            // Static fields
            const default_MPU = action.payload.defaults.MPU;
            const default_DPU = action.payload.defaults.DPU;
            const default_Utils = action.payload.defaults.utils;
            const capacity = action.payload.defaults.cap;
            const default_STUE = action.payload.defaults.STUE;
            const default_SV = action.payload.defaults.SV;

            // Caculated fields
            const default_DPH = ((default_DPU / default_MPU) * 60) // - ((default_Utils / 48) * 60);
            const default_PTUF = Math.round((default_MPU * capacity) / 60);

            // Update values in stats slice
            state[8].value = default_MPU;
            state[9].value = default_DPH;
            state[10].default_value = default_DPU;
            state[10].value = default_DPU;
            state[14].value = default_SV;
            state[15].value = default_STUE;
            state[16].value = default_PTUF;
            state[21].reducer = action.payload.reducer.reducer;
            state[21].data = action.payload.data;
        },
        // Change the checked element to the opposite of its current value
        toggleChecked: (state, action) => {
            const index = state.findIndex((checked) => checked.id === action.payload.id);
            state[index].checked = action.payload.checked;
        },
        // Update values from user selections
        updateLocation: (state, action) => {
            state[3].value = action.payload.value;
        },
        optimizeBusiness: (state) => {
            // is much more involved than I thought... comeback to
        },
        // calculate business efficiency
        calcBE: (state, action) => {
            const index = state.findIndex((stat) => stat.id === action.payload.id);
            
            if(state[index].checked === true) {
                state[7].value = state[7].value + action.payload.data.BE_inc;
            } else if(state[index].checked === false) {
                state[7].value = state[7].value - action.payload.data.BE_inc;
            }
        },
        // calculate minutes per unit
        calcMPU: (state, action) => {
            const index = state.findIndex((stat) => stat.id === action.payload.id);
            
            if(state[index].checked === true) {
                state[8].value = state[8].value - action.payload.data.MPU_inc;
            } else if(state[index].checked === false) {
                state[8].value = state[8].value + action.payload.data.MPU_inc;
            }
        },
        // calculate dollars per unit
        calcDPU: (state, action) => {
            const equipment = state[4].checked;
            const staff = state[6].checked;
            const close_far = state[18].checked;

            if(equipment === true && staff === true && close_far === true) {
                state[10].value = action.payload.data.ttt;
            } else if(equipment === true && staff === true && close_far === false) {
                state[10].value = action.payload.data.ttf;
            } else if(equipment === true && staff === false && close_far === false) {
                state[10].value = action.payload.data.tff;
            } else if(equipment === true && staff === false && close_far === true) {
                state[10].value = action.payload.data.tft;
            } else if(equipment === false && staff === true && close_far === true) {
                state[10].value = action.payload.data.ftt;
            } else if(equipment === false && staff === true && close_far === false) {
                state[10].value = action.payload.data.ftf;
            } else if(equipment === false && staff === false && close_far === true) {
                state[10].value = action.payload.data.fft;
            } else if(equipment === false && staff === false && close_far === false) {
                state[10].value = action.payload.data.fff;
            }
        },
        // calculate dollars per hour
        calcDPH: (state) => {
            const current_MPU = state[8].value;
            const current_DPU = state[10].value;
            
            state[9].value = (current_DPU / current_MPU) * 60;
        },
        // calculate upgrades cost
        calcUC: (state, action) => {
            const index = state.findIndex((stat) => stat.id === action.payload.id);
            
            if(state[index].checked === true) {
                state[11].value = state[11].value + action.payload.data.cost;
                state[12].value = state[12].value + action.payload.data.cost;
            } else if(state[index].checked === false) {
                state[11].value = state[11].value - action.payload.data.cost;
                state[12].value = state[12].value - action.payload.data.cost;
            }
        },
        // calculate total cost
        calcTC: (state) => {
            state[11].value = state[12].value + state[3].value;
        },
        // calculate payback period
        calcPP: (state) => {
            const TC = state[11].value;
            const DPH = state[9].value;
            state[13].value = Math.round(TC / DPH);
        },
        // calculate supply time until empty
        calcSTUE: (state, action) => {
            const index = state.findIndex((stat) => stat.id === action.payload.id);
            
            if(state[index].checked === true) {
                state[15].value = state[15].value + 0.5;
            } else if (state[index].checked === false) {
                state[15].value = state[15].value - 0.5;
            }
        },
        // calculate product time until full
        calcPTUF: (state, action) => {
            const current_MPU = state[8].value;
            const capacity = action.payload.data.cap;
            
            state[16].value = Math.round((current_MPU * capacity) / 60);
        },
        // calculate sale value
        calcSV: (state, action) => {            
            const equipment = state[4].checked;
            const staff = state[6].checked;
            const close_far = state[18].checked;
            const steal_buy = state[19].checked;
            
            if(equipment === false && staff === false && close_far === false && steal_buy === false) {
                state[14].value = action.payload.data.ffff;
            } else if (equipment === true && staff === true && close_far === true && steal_buy === true) {
                state[14].value = action.payload.data.tttt;
            } else if (equipment === true && staff === false && close_far === false && steal_buy === false) {
                state[14].value = action.payload.data.tfff;
            } else if (equipment === false && staff === true && close_far === false && steal_buy === false) {
                state[14].value = action.payload.data.ftff;
            } else if (equipment === false && staff === false && close_far === true && steal_buy === false) {
                state[14].value = action.payload.data.fftf;
            } else if (equipment === false && staff === false && close_far === false && steal_buy === true) {
                state[14].value = action.payload.data.ffft;
            } else if (equipment === true && staff === true && close_far === false && steal_buy === false) {
                state[14].value = action.payload.data.ttff;
            } else if (equipment === true && staff === false && close_far === true && steal_buy === false) {
                state[14].value = action.payload.data.tftf;
            } else if (equipment === true && staff === false && close_far === false && steal_buy === true) {
                state[14].value = action.payload.data.tfft;
            } else if (equipment === false && staff === true && close_far === true && steal_buy === false) {
                state[14].value = action.payload.data.fttf;
            } else if (equipment === false && staff === true && close_far === false && steal_buy === true) {
                state[14].value = action.payload.data.ftft;
            } else if (equipment === false && staff === false && close_far === true && steal_buy === true) {
                state[14].value = action.payload.data.fftt;
            } else if (equipment === true && staff === true && close_far === true && steal_buy === false) {
                state[14].value = action.payload.data.tttf;
            } else if (equipment === true && staff === true && close_far === false && steal_buy === true) {
                state[14].value = action.payload.data.ttft;
            } else if (equipment === true && staff === false && close_far === true && steal_buy === true) {
                state[14].value = action.payload.data.tftt;
            } else if (equipment === false && staff === true && close_far === true && steal_buy === true) {
                state[14].value = action.payload.data.fttt;
            }
        },
    }
});

export const {
    resetStats,
    initDashboard,
    toggleChecked,
    updateLocation,
    optimizeBusiness,
    calcBE, calcMPU,
    calcDPH, calcDPU,
    calcTC, calcUC,
    calcPP, calcSV,
    calcSTUE, calcPTUF
} = statsEngine.actions;

export default statsEngine.reducer;