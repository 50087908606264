import React from 'react';
import { useState } from 'react';
import { UseAppSelector, UseAppDispatch } from '../../../../store/hooks';
import { toggleChecked, calcBE, calcDPU, calcDPH, calcPP, calcSV } from '../../../../store/statsEngine';
import { Diagram2 } from 'react-bootstrap-icons';
import OptimalBusinessModal from '../../../flex/calculators/optimalBusinessModal';
import Pulse from 'react-reveal/Pulse';

import '../../../../styles/mobile/calculators/sub_components/mobileBusinessModifiers.css';

function MobileBusinessModifiers() {
    const dispatch = UseAppDispatch();

    // UI states
    const [modalShow, setModalShow] = useState(false);

    // redux
    const statsEngine = UseAppSelector((state) => state.stats);
    const dataObject = statsEngine.find(item => item.id === 21);
    const data = dataObject.data;

    const sellOption = data.find(item => item.title === 'sell-close-far');
    const supplyOption = data.find(item => item.title === 'buy-steal-supplies');
    const saleValues = data.find(item => item.title === 'sale-values');
    const DPUValues = data.find(item => item.title === 'DPU-values');

    // click handlers
    function handleChecked(id) {
        const checkedState = statsEngine.find(item => item.id === id);
        dispatch(toggleChecked({ id: id, checked: !checkedState.checked }));
    }
    
    function handleSaleToggle(id, data, SV_data, DPU_data) {
        handleChecked(id);
        dispatch(calcDPU({ data: DPU_data }));
        dispatch(calcDPH({}));
        dispatch(calcSV({ data: SV_data }));
        dispatch(calcBE({ id: id, data: data }));
        dispatch(calcPP({}));
    }

    function handleSupplyToggle(id, data, SV_data) {
        handleChecked(id);
        dispatch(calcSV({ data: SV_data }));
        dispatch(calcBE({ id: id, data: data }));
        dispatch(calcPP({}));
    }

    return (
        <Pulse>
            <div className="modifiers-info">
                <div className="arrow"></div>

                {/* <!-- Button trigger modal --> */}
                <button type="button" className="optimize-btn" onClick={() => setModalShow(true)}>
                    <Diagram2 className="svg"/>
                    <p>Optimal Business</p>
                </button>

                <div>
                    <OptimalBusinessModal show={modalShow} onHide={() => setModalShow(false)} />
                </div>

                <input type="checkbox" className="toggle" id="sale-option" onClick={() => handleSaleToggle(18, sellOption, saleValues, DPUValues)}></input>
                <label htmlFor="sale-option" data-checked="Sell Far" data-unchecked="Sell Close"></label>

                <input type="checkbox" className="toggle" id="supp-option" onClick={() => handleSupplyToggle(19, supplyOption, saleValues)}></input>
                <label htmlFor="supp-option" data-checked="Steal Supplies" data-unchecked="Buy Supplies"></label>
            </div>
        </Pulse>
    )
}

export default MobileBusinessModifiers;