import React from 'react';
import Slide from 'react-reveal/Slide';

import '../../../../styles/mobile/calculators/sub_components/mobileSalesSupplyInfo.css';

function MobileSalesSupplyInfo() {
    return (
        <Slide top>
            <div className="salessupply-info">
                <div className="arrow"></div>
                <h2>Sales / Supply</h2>
                <hr/>
            </div>
        </Slide>
    )
}

export default MobileSalesSupplyInfo;