import React from 'react';
import Slide from 'react-reveal/Slide';

import '../../../../styles/mobile/calculators/sub_components/mobilePerformanceInfo.css';

function MobilePerformanceInfo() {
    return (
        <Slide top>
            <div className="performance-info">
                <div className="arrow"></div>
                <h2>Performance Stats</h2>
                <hr/>
            </div>
        </Slide>
    )
}

export default MobilePerformanceInfo;
