import { createSlice } from '@reduxjs/toolkit';

import img1 from '../images/counterfeit_images/maps/all_counterfeit_locations.png';
import img2 from '../images/counterfeit_images/maps/vespucci_canals_counterfeit.png';
import img3 from '../images/counterfeit_images/maps/cypress_flats_counterfeit.png';
import img4 from '../images/counterfeit_images/maps/paleto_bay_counterfeit.png';
import img5 from '../images/counterfeit_images/maps/grand_senora_desert_counterfeit.png';
import equipment_img from '../images/counterfeit_images/counterfeit_equipment_upgrade.png';
import staff_img from '../images/counterfeit_images/counterfeit_staff_upgrade.png';
import security_img from '../images/counterfeit_images/counterfeit_security_upgrade.png';
import banner_img from '../images/counterfeit_images/counterfeit_banner.png';

const counterfeitSlice = createSlice({
    name: 'counterfeit',
    initialState: [
        // Location Filter Options
        { id: 0, filter_title1: 'Free', filter_title2: '-1 Million', filter_title3: '+1 Million' },
        // Location options data
        { id: 1, image: img1, title: 'Twitch Prime', category: 'free', cost: 0 },
        { id: 2, image: img2, title: 'Vesupcci Canals', category: 'over', cost: 1605500 },
        { id: 3, image: img3, title: 'Cypress Flats', category: 'over', cost: 1267500 },
        { id: 4, image: img4, title: 'Paleto Bay', category: 'under', cost: 951000 },
        { id: 5, image: img5, title: 'Grand Senora Desert', category: 'under', cost: 845000 },
        // Business specific data
        { id: 6, title: 'current-reducer', reducer: 'cash', banner: banner_img },
        {
            id: 7,
            title: 'no-upgrade',
            cost: 0,
            utils: 3000,
            DPU: 3500,
            MPU: 12,
            cap: 40,
            STUE: 1.5,
            SV: 119000,
            sale_max: 300000
        },
        {
            id: 8,
            title: 'equip-upgrade',
            display_title: 'Equipment Upgrade',
            image: equipment_img,
            cost: 880000,
            utils_inc: 1000,
            DPU_inc: 700,
            MPU_inc: 2,
            cap: 40,
            STUE_inc: 0.5,
            BE_inc: 20
        },
        {
            id: 9,
            title: 'staff-upgrade',
            display_title: 'Staff Upgrade',
            image: staff_img,
            cost: 273000,
            utils_inc: 1500,
            DPU_inc: 700,
            MPU_inc: 2,
            cap: 40,
            STUE_inc: 0.5,
            BE_inc: 30
        },
        {
            id: 10,
            title: 'security-upgrade',
            display_title: 'Security Upgrade',
            image: security_img,
            cost: 456000,
            utils_inc: 500,
            DPU_inc: 0,
            MPU_inc: 0,
            cap: 40,
            STUE_inc: 0,
            BE_inc: 0
        },
        {
            id: 11,
            title: 'sell-close-far',
            cap: 40,
            BE_inc: 20,
        },
        {
            id: 12,
            title: 'buy-steal-supplies',
            cap: 40,
            BE_inc: 20,
        },
        {
            id: 13,
            title: 'sale-values', 
            ffff: 119000,
            tttt: 294000,
            tfff: 142800,
            ftff: 142800,
            fftf: 178500,
            ffft: 140000,
            ttff: 166600,
            tftf: 214200,
            tfft: 168000,
            fttf: 214200,
            ftft: 168000,
            fftt: 210000,
            tttf: 249900,
            ttft: 196000,
            tftt: 252000,
            fttt: 252000
        },
        {
            id: 14,
            title: 'DPU-values', 
            ttt: 7350,
            ttf: 4900,
            tft: 6300,
            tff: 4200,
            ftt: 6300,
            ftf: 4200,
            fft: 5250,
            fff: 3500
        },
    ],
    reducers: {
        
    }
});

export const {  } = counterfeitSlice.actions;

export default counterfeitSlice.reducer;