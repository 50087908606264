import { createSlice } from '@reduxjs/toolkit';

import img1 from '../images/forgery_images/maps/all_forgery_locations.png';
import img2 from '../images/forgery_images/maps/textile_city_forgery.png';
import img3 from '../images/forgery_images/maps/elysian_island_forgery.png';
import img4 from '../images/forgery_images/maps/paleto_bay_forgery.png';
import img5 from '../images/forgery_images/maps/grapeseed_forgery.png';
import equipment_img from '../images/forgery_images/forgery_equipment_upgrade.png';
import staff_img from '../images/forgery_images/forgery_staff_upgrade.png';
import security_img from '../images/forgery_images/forgery_security_upgrade.png';
import banner_img from '../images/forgery_images/forgery_banner.jpg';

const forgerySlice = createSlice({
    name: 'forgery',
    initialState: [
        // Location Filter Options
        { id: 0, filter_title1: 'Free', filter_title2: '-1 Million', filter_title3: '+1 Million' },
        // Location options data
        { id: 1, image: img1, title: 'Twitch Prime Forgery', category: 'free', cost: 0 },
        { id: 2, image: img2, title: 'Textile City Forgery', category: 'over', cost: 1235000 },
        { id: 3, image: img3, title: 'Elysian Island Forgery', category: 'under', cost: 975000 },
        { id: 4, image: img4, title: 'Paleto Bay Forgery', category: 'under', cost: 732000 },
        { id: 5, image: img5, title: 'Grapeseed Forgery', category: 'under', cost: 650000 },
        // Business specific data
        { id: 6, title: 'current-reducer', reducer: 'forgery', banner: banner_img },
        {
            id: 7,
            title: 'no-upgrade',
            cost: 0,
            utils: 1500,
            DPU: 1000,
            MPU: 5,
            cap: 60,
            STUE: 1.5,
            SV: 51000,
            sale_max: 150000
        },
        {
            id: 8,
            title: 'equip-upgrade',
            display_title: 'Equipment Upgrade',
            image: equipment_img,
            cost: 550000,
            utils_inc: 500,
            DPU_inc: 200,
            MPU_inc: 1,
            cap: 60,
            STUE_inc: 0.5,
            BE_inc: 20
        },
        {
            id: 9,
            title: 'staff-upgrade',
            display_title: 'Staff Upgrade',
            image: staff_img,
            cost: 195000,
            utils_inc: 750,
            DPU_inc: 200,
            MPU_inc: 1,
            cap: 60,
            STUE_inc: 0.5,
            BE_inc: 30
        },
        {
            id: 10,
            title: 'security-upgrade',
            display_title: 'Security Upgrade',
            image: security_img,
            cost: 285000,
            utils_inc: 250,
            DPU_inc: 0,
            MPU_inc: 0,
            cap: 60,
            STUE_inc: 0,
            BE_inc: 0
        },
        {
            id: 11,
            title: 'sell-close-far',
            cap: 60,
            BE_inc: 20,
        },
        {
            id: 12,
            title: 'buy-steal-supplies',
            cap: 60,
            BE_inc: 20,
        },
        {
            id: 13,
            title: 'sale-values', 
            ffff: 51000,
            tttt: 126000,
            tfff: 61200,
            ftff: 61200,
            fftf: 76500,
            ffft: 60000,
            ttff: 71400,
            tftf: 91800,
            tfft: 72000,
            fttf: 91800,
            ftft: 72000,
            fftt: 90000,
            tttf: 107100,
            ttft: 84000,
            tftt: 108000,
            fttt: 108000
        },
        {
            id: 14,
            title: 'DPU-values', 
            ttt: 2100,
            ttf: 1400,
            tft: 1800,
            tff: 1200,
            ftt: 1800,
            ftf: 1200,
            fft: 1500,
            fff: 1000
        },
    ],
    reducers: {
        
    }
});

export const {  } = forgerySlice.actions;

export default forgerySlice.reducer;