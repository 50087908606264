/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "@fontsource/open-sans" // Defaults to weight 400 with all styles included.

import wrapWithProvider from './wrap-with-provider'
export const wrapRootElement = wrapWithProvider
